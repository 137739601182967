@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
  color-scheme: dark;
}

@font-face {
  font-family: "Wonton";
  src: url(/assets/fonts/Wonton_by_Da_Font_Mafia_HUN.otf) format("opentype");
  src: url(/assets/fonts/Wonton_by_Da_Font_Mafia_HUN.ttf) format("truetype");
}

header nav.menu-open {
  #menu-toggle-button {
    & > svg:first-of-type {
      @apply hidden;
    }
    & > svg:last-of-type {
      @apply block;
    }
  }
  #mobile-menu {
    @apply block md:hidden;
  }
}

@layer base {
  body {
    @apply font-body text-gray-900 dark:text-gray-300 bg-white dark:bg-gray-900 overflow-x-hidden flex flex-col min-h-screen;
  }

  header {
    @apply sticky top-0 z-50 bg-white dark:bg-gray-900 select-none;

    nav {
      .menu a {
        @apply px-3 py-2 rounded-md font-medium;

        &:not(.active) {
          @apply text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-800 hover:text-black dark:hover:text-white;
        }
        &.active {
          @apply text-black dark:text-white bg-gray-100 dark:bg-black;
        }
      }
    }
  }

  main {
    @apply relative;

    & > #content {
      @apply max-w-7xl mx-auto py-6 px-6 lg:px-8;
    }
  }

  footer {
    @apply select-none text-center p-4 mt-auto;
  }

  h1 {
    @apply text-3xl pb-4;
  }
  h2 {
    @apply text-2xl pb-3;
  }
  h3 {
    @apply text-xl pb-2;
  }
  h4 {
    @apply text-lg pb-1;
  }

  p {
    @apply py-2;
  }

  a,
  button {
    -webkit-tap-highlight-color: transparent;
    @apply focus:outline-none focus-visible:ring-1 focus-visible:ring-gray-300 dark:focus-visible:ring-gray-600;
  }

  a.link-visible {
    @apply hover:text-gray-700 dark:hover:text-gray-400 underline decoration-1 underline-offset-4 hover:no-underline;
  }

  .img-container {
    background-size: 100%;
    background-repeat: no-repeat;

    img {
      @apply w-full invisible;
    }
  }

  .flex.items-center > svg {
    @apply flex-none;
  }

  .icon-button {
    @apply mx-0.5 p-2 rounded-full text-gray-600 dark:text-gray-400;

    &:not(:disabled) {
      @apply hover:text-black dark:hover:text-white hover:bg-gray-300 dark:hover:bg-gray-800;
    }

    &:disabled {
      @apply cursor-default;
    }
  }
}

body.scrolled header {
  @apply shadow dark:shadow-2xl;
}
